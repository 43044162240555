export default {
  failed: 'Action failed',
  success: 'Action was successful',
  common: {
    inputText: 'Please input',
    selectText: 'Please select',
    startTimeText: 'Start time',
    endTimeText: 'End time',
    login: 'Login',
    required: 'This is required',
    loginOut: 'Login out',
    document: 'Document',
    profile: 'User Center',
    reminder: 'Reminder',
    loginOutMessage: 'Exit the system?',
    back: 'Back',
    ok: 'OK',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    reload: 'Reload current',
    success: 'Success',
    closeTab: 'Close current',
    closeTheLeftTab: 'Close left',
    closeTheRightTab: 'Close right',
    closeOther: 'Close other',
    closeAll: 'Close all',
    prevLabel: 'Prev',
    nextLabel: 'Next',
    skipLabel: 'Jump',
    doneLabel: 'End',
    menu: 'Menu',
    menuDes: 'Menu bar rendered in routed structure',
    collapse: 'Collapse',
    collapseDes: 'Expand and zoom the menu bar',
    tagsView: 'Tags view',
    tagsViewDes: 'Used to record routing history',
    tool: 'Tool',
    toolDes: 'Used to set up custom systems',
    query: 'Query',
    reset: 'Reset',
    shrink: 'Put away',
    expand: 'Expand',
    confirmTitle: 'System Hint',
    exportMessage: 'Whether to confirm export data item?',
    importMessage: 'Whether to confirm import data item?',
    createSuccess: 'Create Success',
    updateSuccess: 'Update Success',
    delMessage: 'Delete the selected data?',
    delDataMessage: 'Delete the data?',
    delNoData: 'Please select the data to delete',
    delSuccess: 'Deleted successfully',
    index: 'Index',
    status: 'Status',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    copy: 'Copy',
    copySuccess: 'Copy Success',
    copyError: 'Copy Error',
    processing: 'Processing...',
    submit: 'Submit'
  },
  error: {
    noPermission: `Sorry, you don't have permission to access this page.`,
    pageError: 'Sorry, the page you visited does not exist.',
    networkError: 'Sorry, the server reported an error.',
    returnToHome: 'Return to home'
  },
  login: {
    welcome: 'Welcome',
    message: 'Backstage management system',
    tenantName: 'TenantName',
    username: 'Username',
    password: 'Password',
    code: 'verification code',
    login: 'Sign in',
    toSignUp: 'Create an new account? ',
    signUp: 'Sign up',
    toLogin: 'Already registered? ',
    reLogin: 'Sign in again',
    register: 'Register',
    checkPassword: 'Confirm password',
    remember: 'Remember me',
    hasUser: 'Existing account? Go to login',
    forgetPassword: 'Forget password?',
    usernamePlaceholder: 'Please Enter Username',
    phoneNumberPlaceholder: 'Please Enter Phone Number',
    passwordPlaceholder: 'Please Enter Password',
    codePlaceholder: 'Please Enter Verification Code',
    phoneNumber: 'Phone Number',
    backLogin: 'back',
    loginBtn: 'Log in',
    registerBtn: 'Sign up',
    // 校验相关
    rules: {
      noPhoneError: 'Phone Number is required',
      noPasswordError: 'Password is required',
      phoneNumberError: 'Please enter the correct Phone number',
      passwordError: 'Password must be between 6 and 20 characters'
    }
  },
  captcha: {
    verification: 'Please complete security verification',
    slide: 'Swipe right to complete verification',
    point: 'Please click',
    success: 'Verification succeeded',
    fail: 'verification failed'
  },
  router: {
    login: 'Login',
    home: 'Home',
    product: 'Product',
    wallet: 'Wallet',
    bankAccount: 'Bank Account',
    order: 'Order',
    mine: 'Me',
    productDetail: 'Product Detail',
    paymentRecords: 'Payment Records',
    myTeam: 'My Team',
    payment: 'Payment',
    paymentRecord: 'Payment Record',
    aboutUs: 'About Us',
    faq: 'FAQ',
    share: 'Share'
  },
  form: {
    input: 'Input',
    inputNumber: 'InputNumber',
    default: 'Default',
    icon: 'Icon',
    mixed: 'Mixed',
    textarea: 'Textarea',
    slot: 'Slot',
    position: 'Position',
    autocomplete: 'Autocomplete',
    select: 'Select',
    selectGroup: 'Select Group',
    selectV2: 'SelectV2',
    cascader: 'Cascader',
    switch: 'Switch',
    rate: 'Rate',
    colorPicker: 'Color Picker',
    transfer: 'Transfer',
    render: 'Render',
    radio: 'Radio',
    button: 'Button',
    checkbox: 'Checkbox',
    slider: 'Slider',
    datePicker: 'Date Picker',
    shortcuts: 'Shortcuts',
    today: 'Today',
    yesterday: 'Yesterday',
    aWeekAgo: 'A week ago',
    week: 'Week',
    year: 'Year',
    month: 'Month',
    dates: 'Dates',
    daterange: 'Date Range',
    monthrange: 'Month Range',
    dateTimePicker: 'DateTimePicker',
    dateTimerange: 'Datetime Range',
    timePicker: 'Time Picker',
    timeSelect: 'Time Select',
    inputPassword: 'input Password',
    passwordStrength: 'Password Strength',
    operate: 'operate',
    change: 'Change',
    restore: 'Restore',
    disabled: 'Disabled',
    disablement: 'Disablement',
    delete: 'Delete',
    add: 'Add',
    setValue: 'Set value',
    resetValue: 'Reset value',
    set: 'Set',
    subitem: 'Subitem',
    formValidation: 'Form validation',
    verifyReset: 'Verify reset',
    remark: 'Remark'
  },
  watermark: {
    watermark: 'Watermark'
  },
  dialog: {
    dialog: 'Dialog',
    open: 'Open',
    close: 'Close'
  },
  sys: {
    api: {
      operationFailed: 'Operation failed',
      errorTip: 'Error Tip',
      errorMessage: 'The operation failed, the system is abnormal!',
      timeoutMessage: 'Login timed out, please log in again!',
      apiTimeoutMessage: 'The interface request timed out, please refresh the page and try again!',
      apiRequestFailed: 'The interface request failed, please try again later!',
      networkException: 'network anomaly',
      networkExceptionMsg: 'Please check if your network connection is normal! The network is abnormal',
      errMsg401: 'The user does not have permission (token, user name, password error)!',
      errMsg403: 'The user is authorized, but access is forbidden!',
      errMsg404: 'Network request error, the resource was not found!',
      errMsg405: 'Network request error, request method not allowed!',
      errMsg408: 'Network request timed out!',
      errMsg500: 'Server error, please contact the administrator!',
      errMsg501: 'The network is not implemented!',
      errMsg502: 'Network Error!',
      errMsg503: 'The service is unavailable, the server is temporarily overloaded or maintained!',
      errMsg504: 'Network timeout!',
      errMsg505: 'The http version does not support the request!',
      errMsg901: 'Demo mode, no write operations are possible!'
    },
    app: {
      logoutTip: 'Reminder',
      logoutMessage: 'Confirm to exit the system?',
      menuLoading: 'Menu loading...'
    },
    exception: {
      backLogin: 'Back Login',
      backHome: 'Back Home',
      subTitle403: 'Sorry, you don\'t have access to this page.',
      subTitle404: 'Sorry, the page you visited does not exist.',
      subTitle500: 'Sorry, the server is reporting an error.',
      noDataTitle: 'No data on the current page.',
      networkErrorTitle: 'Network Error',
      networkErrorSubTitle: 'Sorry, Your network connection has been disconnected, please check your network!'
    }
  },
  profile: {
    user: {
      title: 'Personal Information',
      username: 'User Name',
      nickname: 'Nick Name',
      mobile: 'Phone Number',
      email: 'User Mail',
      dept: 'Department',
      posts: 'Position',
      roles: 'Own Role',
      sex: 'Sex',
      man: 'Man',
      woman: 'Woman',
      createTime: 'Created Date'
    },
    info: {
      title: 'Basic Information',
      basicInfo: 'Basic Information',
      resetPwd: 'Reset Password',
      userSocial: 'Social Information'
    },
    rules: {
      nickname: 'Please Enter User Nickname',
      mail: 'Please Input The Email Address',
      truemail: 'Please Input The Correct Email Address',
      phone: 'Please Enter The Phone Number',
      truephone: 'Please Enter The Correct Phone Number'
    },
    password: {
      oldPassword: 'Old PassWord',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      oldPwdMsg: 'Please Enter Old Password',
      newPwdMsg: 'Please Enter New Password',
      cfPwdMsg: 'Please Enter Confirm Password',
      pwdRules: 'Passwords must be between 6 and 20 characters',
      diffPwd: 'The Passwords Entered Twice No Match'
    }
  },
  user: {
    balance: 'Balance',
    totalIncome: 'Total Income',
    todayIncome: 'Today\'s Income',
    totalCommission: 'Total Commission',
    todayCommission: 'Today\'s Commission',
    paymentRecords: 'Payment Records'
  },
  product: {
    dailyIncome: 'Daily Income',
    totalIncome: 'Total Income',
    buy: 'Buy',
    placeAnOrder: 'Place an Order',
    price: 'Price',
    serving: 'Serving',
    day: 'day',
    days: 'days',
    viewDetail: 'View & Buy >',
    investmentPlans: 'Investment Plans',
    investmentPlansInfo: 'Select a packaged plan below to start your earning journey. Click on it to enlarge the picture and view further product information.',
  },
  order: {
    dailyIncome: 'Daily Income',
    totalIncome: 'Total Income',
    alreadyObtained: 'Already Obtained',
    servingTime: 'Serving Time',
    day: 'day',
    days: 'days',
    dailyReturnTime: 'Daily return time',
    nextReturnTime: 'Upcoming earning time',
    price: 'Price',
    orderNotPaid: 'Not Paid',
  },
  bank: {
    card: 'Card',
    cardInfo: 'Card Information',
    holderName: 'Account Holder\'s Name',
    bankName: 'Bank Name',
    cardNo: 'Account Number',
    ifsc: 'IFSC',
    holderNamePlaceholder: 'Please Enter Your full Name',
    cardNoPlaceholder: 'Please Enter Your Account Number',
    ifscPlaceholder: 'Please Enter IFSC',
    rules: {
      noHolderNameError: 'Account Holder\'s Name is required',
      noBankIdError: 'Please Select Bank Name',
      noCardNoError: 'Account Number is Required',
      noIfscError: 'IFSC is Required',
      ifscError: 'Please enter correct IFSC',
    },
    remark: 'Please type the information of bank account accurately. We will not be responsible for capital loss caused by information error.'
  },
  withdraw: {
    withdraw: 'Withdraw',
    withdrawRecord: 'Withdraw Records',
    withdrawAmount: 'Withdrawal amount',
    withdrawAmountPlaceholder: 'Please Enter withdrawal amount',
    // 校验相关
    rules: {
      noWithdrawAmountError: 'Withdrawal amount is required',
      withdrawAmountError: 'Withdrawal amount must larger than Minimum Withdrawal Amount'
    },
    bankNotOk: 'Bank information is incomplete',
    aboutWithdraw: 'About Withdrawal',
    processTime: 'Processing Time',
    minimumAmount: 'Minimum Withdrawal',
    arrivalTime: 'Arrival Time',
    fees: 'Fees',
    withdrawBtn: 'Withdraw',
    withdrawNo: 'No',
    price: 'Price',
    withdrawStatus: {
      auditing: 'processing',
      approved: 'processing',
      success: 'success',
      'audit failed': 'failed',
      'withdraw failed': 'failed',
    }
  },
  payment: {
    productName: 'Name',
    productPrice: 'Price',
    chooseMethod: 'Choose a payment method',
    chooseMethodError: 'Please choose a payment method',
    pay: 'Pay',
    paySuccess: 'Pay Success',
    payFailed: 'Pay Failed',
  },
  myTeam: {
    level: 'Level',
    numbers: 'Numbers',
    totalCommission: 'Total Commission',
    todayCommission: 'Today Commission',
    commission: {
      invite: 'Invite',
      commissionList: 'Commission list',
      totalRecharge: 'Total Recharge',
      myCommission: 'My Commission',
    },
  },
  share: {
    link: 'Link',
    promotionMaterial: 'Promotional Material',
    promotionContent: 'New legit earning platform has been launched. Here you can either invest a plan to earn huge daily income or invite your friends to earn commissions. Referral commissions of 3 levels are 30%, 4% and 1%. Register to get bonus NGN 200 and Free NGN 1000 worthy product. Signup though the below link'
  },
  paymentRecord: {
    paymentRecord: 'Payment Records',
    remark: 'If your product not reflected after paid, please follow this to upload your Transaction Receipt Which Should Contain Payment Time, and remember to submit to Us for Review, Your payment order will be confirmed in max 24H',
    payStatus: {
      paid: 'Paid',
      unPaid: 'Unpaid',
      pending: 'Pending'
    },
    orderNo: 'Pay No',
    productName: 'Product',
    price: 'Price',
    upload: 'Upload',
    uploadBtn: 'Upload Proof',
    congrats: 'Congrats!',
    uploadProof: 'Upload Proof',
    uploadImg: 'Please select a shortcut',
    uploadProofDesc: 'Description',
    uploadProofDescPlaceHolder: 'Please Enter The Description'
  },
  mine: {
    myTeam: 'My Team',
    faq: 'FAQ',
    official: 'Official Channel',
    paymentRecord: 'Payment Record',
    referralLink: 'Referral Link',
    bankAccount: 'Bank Account',
    aboutUs: 'About Us',
    logOut: 'Log Out',
    logOutMessage: 'Are you sure you want to log out?'
  }
};