<script setup lang="ts">

defineOptions({
  name: 'OrderIncome'
})

type IncomeSummary = {
  totalIncome: number;
  todayIncome: number;
}

import { getIncomeSummary } from '@/api/finance/trading/pay/wallet/transaction';
import { fenToYuan, processPriceUnit } from '@/utils/price';

const { t } = useI18n();
const { push } = useRouter();

const totalIncome = ref(0);
const todayIncome = ref(0);

const getIncome = async () => {
  const res: IncomeSummary = await getIncomeSummary();
  totalIncome.value = fenToYuan(res.totalIncome);
  todayIncome.value = fenToYuan(res.todayIncome);
};

onActivated(() => {
  getIncome();
})


</script>

<template>
<div class="row q-pt-md text-center text-caption text-weight-medium">
<!--total income-->
  <div class="col-12 row q-pr-xs radius-sm bg-white">
    <div class="col q-py-md q-px-sm row justify-center ">
      <div v-text="processPriceUnit(totalIncome)"
           class="col-12 text-h6 text-primary text-weight-bolder ellipsis" />
      <div v-text="t('user.totalIncome')" class="ellipsis"></div>
    </div>
    <q-separator vertical inset spaced/>
<!--  </div>-->
<!--  &lt;!&ndash;today income&ndash;&gt;-->
<!--  <div class="col-6 q-pr-xs">-->
    <div class="col q-py-md q-px-sm row justify-center items-center ">
      <div v-text="processPriceUnit(todayIncome)"
           class="col-12 text-h6 text-primary text-weight-bolder ellipsis" />
      <div v-text="t('user.todayIncome')" class="ellipsis"></div>
    </div>
  </div>
  <!--record-->
<!--  <div class="col-4" @click="push({name: 'PaymentRecords'})">-->
<!--    <div class="q-py-md q-px-xs row justify-center full-height items-center radius-sm bg-white">-->
<!--      <div v-text="t('user.paymentRecords')" class="text-blue-7 ellipsis"></div>-->
<!--    </div>-->
<!--  </div>-->
</div>
</template>

<style scoped>

</style>