<script setup lang="ts">

import { useUserStore } from '@/stores/user';
import { processPriceUnit } from '@/utils/price';

const { t } = useI18n();

defineOptions({
  name: 'MyBalance'
});

const { currentRoute } = useRouter();

// 从userStore获取id和余额
const { getMobile, getBalance } = toRefs(useUserStore());
const { updateBalance } = useUserStore();

// onMounted(() => {
//   updateBalance();
// })

// watch(
//   () => currentRoute.value,
//   () => {
//     return debounce(() => updateBalance(), 100);
//   }
// )

onActivated(() => {
  updateBalance();
});


</script>

<template>
  <div class="row no-wrap q-px-md q-py-sm bg-common text-body1 text-weight-medium">
    <div class="col">{{ `ID ${getMobile}` }}</div>
    <div class="col text-right">{{ t('user.balance') }} {{ processPriceUnit(getBalance) }}</div>
    <!--    <div class="col text-h5 text-weight-bold">{{ processPriceUnit(getBalance) }}</div>-->
  </div>
</template>

<style scoped>

</style>