import request from '@/config/axios'

/** 用户钱包查询参数 */
export interface PayWalletUserReqVO {
  userId: number
}
/** 钱包 VO */
export interface WalletVO {
  balance: number
  totalExpense: number
  totalRecharge: number
}

/** 银行 VO */
export interface BankInfoVO {
  cardNo: string
  holderName: string
  bankId: number
  walletExtra: WalletExtraVO
}

/** 额外参数 VO */
export interface WalletExtraVO {
  ifsc: string
}

/** 查询用户钱包详情 */
export const getWallet = async () => {
  return await request.get<WalletVO>({ url: `/pay/wallet/get` })
}

/** 查询用户银行详情 */
export const getBankInfo = async () => {
  return await request.get<BankInfoVO>({ url: `/pay/wallet/get-bank` })
}

/** 更新用户银行详情 */
export const setBankInfo = async (data: BankInfoVO) => {
  return await request.post({ url: `/pay/wallet/set-bank`, data  })
}