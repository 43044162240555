import request from '@/config/axios'

export interface PayOrderVO {
  no: string
  subject: string
  price: number
  status: number
  proofImg: string
  proofDesc: string
  createTime: string
}

export interface OrderPageReqVO extends PageParam {
  appId?: number
  channelCode?: string
  no?: string
  status?: number
}

export interface OrderExportReqVO {
  merchantId?: number
  appId?: number
  channelId?: number
  channelCode?: string
  merchantOrderId?: string
  subject?: string
  body?: string
  notifyUrl?: string
  notifyStatus?: number
  amount?: number
  channelFeeRate?: number
  channelFeeAmount?: number
  status?: number
  expireTime?: Date[]
  successTime?: Date[]
  notifyTime?: Date[]
  successExtensionId?: number
  refundStatus?: number
  refundTimes?: number
  channelUserId?: string
  channelOrderNo?: string
  createTime?: Date[]
}

// 查询列表支付订单
export const getOrderPage = async (params: OrderPageReqVO) => {
  return await request.get({ url: '/pay/order/page', params })
}

// 查询详情支付订单
export const getOrder = async (id: number) => {
  return await request.get({ url: '/pay/order/get?id=' + id })
}

// 获得支付订单的明细
export const getOrderDetail = async (id: number) => {
  return await request.get({ url: '/pay/order/get-detail?id=' + id })
}

// 提交支付订单
export const submitOrder = async (data: any) => {
  return await request.post({ url: '/pay/order/submit', data })
}

// 提交支付订单
export const updateOrderProof = async (data: any) => {
  return await request.post({ url: '/pay/order/update-proof', data })
}