<script setup lang="ts">

import { string, func } from 'vue-types';

defineOptions({
  name: 'CommonItem'
})

const { push } = useRouter()
const { t } = useI18n()

const props = defineProps({
  iconName: string().isRequired,
  label: string().isRequired,
  clickFn: func().isRequired
})

</script>

<template>
  <q-item clickable v-ripple @click="clickFn">
    <q-item-section top avatar>
      <q-avatar round color="common">
        <q-icon :name="iconName" size="md"/>
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label class="text-subtitle1 text-weight-bolder">
        {{ label }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<style scoped>

</style>