<script setup lang="ts">

import { getBankList } from '@/api/finance/trading/pay/bank';
import { useUserStore } from '@/stores/user';
import { QNotify } from '@/utils/QNotify';
import { BankInfoVO, WalletExtraVO } from '@/api/finance/trading/pay/wallet';
import { QLoading } from '@/utils/QLoading';
import { useChannelStore } from '@/stores/channel';

defineOptions({
  name: 'BankCardForm'
});

const emit = defineEmits(['success']);

const { t } = useI18n();
const userStore = useUserStore();

const { getBankInfo, setBankInfo } = userStore;

const bankData = ref<BankInfoVO>({
  walletExtra: {} as WalletExtraVO
} as BankInfoVO);

const validateIFSC = (ifsc: string) => {
  // 正则表达式：前4位为字母，第5位为0，后面6位任意
  const regex = /^[A-Za-z]{4}0.{6}$/;
  return regex.test(ifsc);
};

const formRules = {
  holderName: [val => !!val || t('bank.rules.noHolderNameError')],
  bankId: [val => !!val || t('bank.rules.noBankIdError')],
  cardNo: [val => !!val || t('bank.rules.noCardNoError')],
  ifsc: [
    val => !!val || t('bank.rules.noIfscError'),
    val => validateIFSC(val) || t('bank.rules.ifscError')
  ]
};

const bankOptions = ref();

const getBankOptions = async () => {
  bankOptions.value = await getBankList();
};

const handleSubmit = async () => {
  // console.log('submit!');
  await setBankInfo(unref(bankData));
  emit('success');
  QNotify.success(t('common.success'));
};

onMounted(async () => {
  QLoading.show();
  await getBankOptions();
  bankData.value = {
    holderName: getBankInfo.holderName,
    bankId: getBankInfo.bankId,
    cardNo: getBankInfo.cardNo,
    walletExtra: getBankInfo.walletExtra || {} as WalletExtraVO
  };
  QLoading.hide();
});

// 是否有印度通道
const { hasIndian } = toRefs(useChannelStore());

</script>

<template>
  <q-form class="q-gutter-y-md q-py-md" ref="bankFormRef" @submit="handleSubmit">
    <!-- 持卡人名称-->
    <div>
      <div class="q-pa-sm text-weight-bold">{{ t('bank.holderName') }}</div>
      <!--               standout="bg-grey-4 text-black" -->
      <q-input v-model="bankData.holderName" dense
               :placeholder="t('bank.holderNamePlaceholder')"
               outlined clearable
               :rules="formRules.holderName"
      >
      </q-input>
    </div>
    <!--银行名称-->
    <div>
      <div class="q-pa-sm text-weight-bold">{{ t('bank.bankName') }}</div>
      <q-select v-model="bankData.bankId"
                dense outlined
                :placeholder="t('common.selectText')"
                :options="bankOptions"
                :rules="formRules.bankId"
                emit-value
                map-options
                option-value="id"
                option-label="bankName"
      />
    </div>
    <!-- 卡号-->
    <div>
      <div class="q-pa-sm text-weight-bold">{{ t('bank.cardNo') }}</div>
      <!--               standout="bg-grey-4 text-black" -->
      <q-input v-model="bankData.cardNo" dense
               :placeholder="t('bank.cardNoPlaceholder')"
               outlined clearable
               :rules="formRules.cardNo"
      >
      </q-input>
    </div>
    <!-- ifsc -->
    <div class="q-px-sm" v-if="hasIndian">
      <div class="q-pa-sm text-weight-bold">{{ t('bank.ifsc') }}</div>
      <!--               standout="bg-grey-4 text-black" -->
      <q-input v-model="bankData.walletExtra.ifsc" dense
               :placeholder="t('bank.ifscPlaceholder')"
               outlined clearable
               :rules="formRules.ifsc" lazy-rules
      >
      </q-input>
    </div>
    <!-- 确认按钮-->
    <div class="q-py-md column">
      <q-btn class="q-py-sm bg-primary"
             :label="t('common.submit')"
             type="submit"
             rounded noCaps flat
      />
    </div>
  </q-form>
</template>

<style scoped>

</style>