<script setup lang="ts">

import { object } from 'vue-types';
import { Spu } from '@/api/finance/trading/product/spu';
import { processProductIncome } from '@/utils/product';
import { processPriceUnit } from '@/utils/price';

defineOptions({
  name: 'ProductItem'
});

const props = defineProps({
  item: object<Spu>().isRequired
});

const { t } = useI18n();

const spu = ref<Spu>(props.item);

const { push } = useRouter();

processProductIncome(spu.value);

</script>

<template>
  <q-card @click="push({name: 'ProductDetail', params: { productId: spu.id}})"
          class="col-5 row q-mb-md items-center radius-sm bg-rey-2 shadow-2 shadow-up-1 text-center"
  >
    <!--图片-->
    <q-card-section class="col-12 q-pa-sm relative-position">
      <q-img :src="spu.picUrl" class="radius-sm" loading="lazy"
             :ratio="1" fit="contain" height="100%"
      >
        <template #loading>
          <div class="text-primary">
            <q-spinner-ios />
            <!--            <div class="q-mt-md">Loading...</div>-->
          </div>
        </template>
      </q-img>
      <div class="absolute-top-left q-pa-md">
        <!-- 期限 -->
        <q-badge class="q-py-xs text-capitalize text-weight-medium"
                 rounded color="secondary" text-color="black"
                 :label="`${spu.totalPeriod} ${spu.totalPeriod > 1 ? t('product.days') : t('product.day')}`" />
      </div>
    </q-card-section>
    <!--名称和income-->
    <q-card-section class="col-12 q-px-sm q-pb-sm q-pt-none text-caption text-left" style="line-height: 1.2em">
      <div v-text="spu.name" class="text-subtitle2 text-weight-bold ellipsis" />
      <div
        class="ellipsis"
        v-text="`${t('product.price')} ${processPriceUnit(spu.price)}`"
      />
      <div
        class="ellipsis"
        v-text="`${t('product.dailyIncome')} ${processPriceUnit(spu.dailyIncome)}`"
      />
      <div
        class="ellipsis"
        v-text="`${t('product.totalIncome')} ${processPriceUnit(spu.totalIncome)}`"
      />
    </q-card-section>

    <q-separator />

    <q-card-actions class="column bg-common q-py-xs full-width">
      <div v-text="t('product.viewDetail')"
           class="col text-subtitle2 text-weight-bold ellipsis" />
    </q-card-actions>
  </q-card>
</template>

<style scoped>

</style>