import request from '@/config/axios'

export interface OrderVO {
  // ========== 订单基本信息 ==========
  id?: number | null // 订单编号
  no?: string // 订单流水号
  createTime?: Date | null // 下单时间
  type?: number | null // 订单类型
  terminal?: number | null // 订单来源
  userId?: number | null // 用户编号
  mobile?: string // 用户手机号
  userIp?: string // 用户 IP
  userRemark?: string // 用户备注
  status?: number | null // 订单状态
  productCount?: number | null // 购买的商品数量
  finishTime?: Date | null // 订单完成时间
  cancelTime?: Date | null // 订单取消时间
  cancelType?: number | null // 取消类型
  remark?: string // 商家备注

  // ========== 订单商品信息 ==========
  incomeStatus: number | null // 收入状态
  productName: string // 商品名称
  dailyIncome: number | null // 日收益
  currPeriod: number | null // 当前周期
  totalPeriod: number | null // 总周期

  // ========== 价格 + 支付基本信息 ==========
  payOrderId?: number | null // 支付订单编号
  payStatus?: boolean // 是否已支付
  payTime?: Date | null // 付款时间
  payChannelCode?: string // 支付渠道
  totalPrice?: number | null // 商品原价（总）
  discountPrice?: number | null // 订单优惠（总）
  deliveryPrice?: number | null // 运费金额
  adjustPrice?: number | null // 订单调价（总）
  payPrice?: number | null // 应付金额（总）

  // 下单用户信息
  user?: {
    id?: number | null
    status?: number | null
    mobile?: string | null
    createTime?: Date | null
  }
  // 订单操作日志
  logs?: OrderLogRespVO[]
}

export interface OrderLogRespVO {
  content?: string
  createTime?: Date
  userType?: number
}

export interface OrderItemVO {
  no: number
  createTime: string
  productName: string

  status: number

  totalPrice: number

  currPeriod: number
  totalPeriod: number

  incomeStatus: number
  dailyIncome: number
  calcIncomeTime: string
  dailyIncomeTimeRange: Array<number[]>
}

// 查询交易订单详情
export const getOrderPage = async (params: PageParam) => {
  return await request.get({ url: `/trade/order/page`, params })
}

// 查询交易订单详情
export const getOrder = async (id: number | null) => {
  return await request.get({ url: `/trade/order/get-detail?id=` + id })
}

// 结算订单
export const settlementOrder = async (productId: number) => {
  return await request.get({ url: `/trade/order/settlement?productId=${productId}`})
}

// 创建订单
export const createOrder = async (data) => {
  return await request.post({ url: `/trade/order/create`, data})
}