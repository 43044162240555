<script setup lang="ts">
defineOptions({
  name: 'ProductList'
});

import { ProductItem } from './index';
import { getSpuList, Spu } from '@/api/finance/trading/product/spu';

const { t } = useI18n();
const productList = ref<Spu[]>([]);

const getProductList = async () => {
  productList.value = await getSpuList();
};

getProductList();

// onActivated(() => {
//   getProductList()
// })

const refresh = async (done) => {
  await getProductList();
  done();
};


</script>

<template>
  <q-card class="scroll q-px-xs q-pt-md col-grow row no-border-radius no-shadow product-list">
    <!--    <q-scroll-area class="q-pt-sm no-border-radius"-->
    <!--                   :thumb-style="{opacity: '0'}"-->
    <!--                   :style="{ height: 'calc(100% - 1rpx)'}"-->
    <!--                   style="width: 100%">-->
    <div class="q-px-md q-pb-sm">
      <div v-text="t('product.investmentPlans')" class="text-h5 text-weight-bold"></div>
      <div v-text="t('product.investmentPlansInfo')" class="text-caption"></div>
    </div>
    <div class="q-pa-xs row justify-around q-gutter-sm">
      <ProductItem v-for="product in productList"
                   :key="product.id"
                   :item="product"
      />
      <!--        数量是奇数时需要占位-->
      <div v-if="productList.length % 2 === 1" class="col-5" />
    </div>

    <!--    </q-scroll-area>-->
  </q-card>
</template>

<style lang="scss" scoped>

</style>