<script setup lang="ts">

import { QNotify } from '@/utils/QNotify';

defineOptions({
  name: 'RecordItem'
});

import UploadProof from './UploadProof.vue';
import { object } from 'vue-types';
import { fenToYuan, processPriceUnit } from '@/utils/price';
import { PayOrderVO, updateOrderProof } from '@/api/finance/trading/pay/order';
import { formatToDateTime } from '@/utils/dateUtil';

const { t } = useI18n();

const emit = defineEmits(['uploaded']);

const props = defineProps({
  item: object<PayOrderVO>().isRequired
});

const showUpload = ref(false);
const payOrder = ref<PayOrderVO>(props.item);

const PayStatus = {
  0: 'unPaid',
  30: 'closed',
  10: 'paid',
  40: 'pending'
};

const payStatus = computed(() => {
  return PayStatus[payOrder.value.status];
});

const price = computed(() => {
  return fenToYuan(payOrder.value.price);
});

const createTime = computed(() => {
  return formatToDateTime(payOrder.value.createTime);
});

const updateProofImg = async (imgUrl: string, proofDesc: string) => {
  await updateOrderProof({
    no: payOrder.value.no,
    proofImg: imgUrl,
    proofDesc
  });
  QNotify.success(t('common.success'));
};

const uploadSuccess = () => {
  showUpload.value = false;
  emit('uploaded');
};


</script>

<template>
  <q-card class="no-shadow radius-sm full-width text-caption text-weight-bold">
    <!--订单号-->
    <q-card-section class="q-pa-sm q-px-md row justify-between text-right text-white text-weight-medium"
                    :class="payStatus === 'paid' ? 'bg-positive' : payStatus === 'pending' ? 'bg-warning' : 'bg-negative'">
      <div class="col-8 row items-center text-left ellipsis">
        {{ t('paymentRecord.orderNo') }}: {{ payOrder.no }}
      </div>
      <q-space />
      <div class="col">
        <q-btn v-if="payStatus != 'paid'" no-caps dense outline rounded
               class="q-px-sm text-caption text-weight-bold"
               :label="t('paymentRecord.upload')"
               :disable="payStatus === 'pending'"
               @click="showUpload = true"
        />
      </div>
    </q-card-section>
    <!--详细信息-->
    <q-card-section class="row">
      <div class="col-8 column">
        <!--        <div class="text-body2 text-weight-bolder">-->
        <!--          {{ t('paymentRecord.productName') }}: {{ payOrder.subject }}-->
        <!--        </div>-->
        <div class="text-primary text-h6 text-weight-bold">
          {{ processPriceUnit(price) }}
        </div>
        <div class="">
          {{ createTime }}
        </div>
      </div>
      <div class="col-4 row text-subtitle2 items-center text-center">
        <div v-if="payStatus === 'paid'" class="col text-positive">{{ t('paymentRecord.congrats') }}</div>
        <div v-else-if="payStatus === 'pending'" class="col text-warning">{{ t('paymentRecord.payStatus.pending') }}
        </div>
        <div v-else class="col text-negative">{{ t('paymentRecord.payStatus.unPaid') }}</div>
      </div>
    </q-card-section>
    <q-dialog v-model="showUpload" full-width>
      <q-card class="radius-sm relative-position">
        <q-card-section class="row items-center q-pb-none">
          <div class="col-11 text-body1 ellipsis"
               v-text="t('paymentRecord.uploadProof')" />
          <q-space />
          <q-btn class="col" icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <UploadProof
            :default-img="payOrder.proofImg"
            :default-desc="payOrder.proofDesc"
            :success-func="updateProofImg"
            @success="uploadSuccess" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<style scoped>

</style>