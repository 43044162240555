<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'Home'
});

import { MyBalance } from '@/views/Common';
import { useCounterStore } from '@/stores/example-store';
import CommonCarousel from '@/components/Carousel/src/CommonCarousel.vue';
import { ProductList } from '@/views/Product/src/components';

const counterStore = useCounterStore();
const { doubleCount  } = toRefs(counterStore);
const { increment } = counterStore

const userStore = useUserStore();
const { incrementBalance, decrementBalance } = userStore;


</script>

<template>
  <q-page class="bg-grey-2 column no-wrap">
    <MyBalance />
    <CommonCarousel type="home" />
<!--    &lt;!&ndash;描述&ndash;&gt;-->
<!--    <CompanyIntro />-->
    <ProductList />
  </q-page>
</template>

<style scoped>

</style>