import { defineStore } from 'pinia';
import { pinia } from './index';
import { ConfigVO, getConfig } from '@/api/finance/manage/config';
import { fenToYuan } from '@/utils/price';
import { QNotify } from '@/utils/QNotify';

export const useConfig = defineStore('app-config', {
  state: () => ({
    isSetConfig: false,
    config: {} as ConfigVO
  }),

  getters: {
    getConfig(): ConfigVO {
      return this.config;
    },
    getCurrencySymbol(): string {
      return this.config.currencySymbol;
    },
    getPhoneDigit(): number {
      return this.config.phoneDigit || 10; // 默认10位
    },
    getProfitRate(): string[] {
      const profitRate = this.config.promotionOrderProfitRate || '';
      return profitRate.split(',');
    },
    getOfficialUrl(): string | null {
      return this.config.officialUrl;
    },
  },

  actions: {
    reset(): void {
      this.isSetConfig = false;
    },
    async updateConfig(): Promise<void> {
      this.config = await getConfig();
      processConfigIn(this.config);
      this.isSetConfig = true;
    },
    toOfficialChannel() {
      const url = this.config.officialUrl;
      if (!url) {
        QNotify.warning('not support yet');
        return;
      }
      window.open(url, '_blank');
    }
  }
});

const processConfigIn = (config: ConfigVO) => {
  // 处理金额
  if (config.minWithdrawNum != null) {
    config.minWithdrawNumYuan = fenToYuan(config.minWithdrawNum);
  }
  if (config.maxWithdrawNum != null) {
    config.maxWithdrawNumYuan = fenToYuan(config.maxWithdrawNum);
  }
};

export const useConfigStore = () => {
  return useConfig(pinia);
};