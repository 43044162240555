import { boot } from 'quasar/wrappers';
import { createI18n, useI18n } from 'vue-i18n';

import messages from '@/i18n';
import { Quasar } from 'quasar';

// const langList = import.meta.glob('/lang/*.js');

// console.log(langList);


export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages['en-US'];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {
  }

  // define the datetime format schema
  export interface DefineDateTimeFormat {
  }

  // define the number format schema
  export interface DefineNumberFormat {
  }
}
/* eslint-enable @typescript-eslint/no-empty-interface */

let locale = 'en-US';

const langMap = {
  // 'zh': 'zh-CN',
  // 'zh-CN': 'zh-CN',
  // 'zh-TW': 'zh-CN',
  // 'zh-HK': 'zh-CN',
  'en': 'en-US',
  'en-US': 'en-US',
  'en-GB': 'en-US',
  'ru': 'ru-RU',
  'ru-RU': 'ru-RU',
  'fr': 'fr-FR',
  'fr-FR': 'fr-FR',
};

const getLocale = () => {
  // const locale = Quasar.lang.getLocale();
  // const langN = navigator.language;
  // console.log('当前浏览器语言：', locale);
  const lang = Quasar.lang.getLocale() || 'en_US';
  locale = langMap[lang] || 'en-US';
  return langMap[lang] || 'en-US'; // 默认英文
};

const i18n = createI18n({
  locale: getLocale(),
  legacy: false,
  messages
});

export default boot(({ app }) => {

  // Set i18n instance on app
  app.use(i18n);

});

export { i18n, locale };