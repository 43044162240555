<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';
import { LevelProfitInviteCard, PromoteList } from './components';

defineOptions({
  name: 'CommissionDetail'
});


const props = defineProps({
  level: {
    type: String,
    required: true
  }
});

const level = props.level as unknown as number;

const { getProfitRate } = toRefs(useConfigStore());

const rate = computed(() => {
  return getProfitRate.value[level - 1] as unknown as number;
});

</script>

<template>
  <q-page class="bg-grey-2 column no-wrap q-pa-md">
    <LevelProfitInviteCard :level="level" :rate="rate" />
    <PromoteList :level="level" />
  </q-page>
</template>

<style scoped>

</style>