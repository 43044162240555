import request from '@/config/axios'

export interface WalletTransactionVO {
  id: number
  walletId: number
  title: string
  price: number
  balance: number
}

// 查询会员钱包流水列表
export const getWalletTransactionPage = async (params) => {
  return await request.get({ url: `/pay/wallet-transaction/page`, params })
}


// 查询会员订单收益统计
export const getIncomeSummary = async () => {
  return await request.get({ url: `/pay/wallet-transaction/get-income` })
}

// 查询会员推广收益统计
export const getPromoteIncomeSummary = async (level: number) => {
  return await request.get({ url: `/pay/wallet-transaction/get-promote-income?level=${level}` })
}