import { useQuasar } from 'quasar';

const formatSvgIconName = (name: string) => {
  if (name.includes('.')) {
    return `img:svg/${name.replace(/_/g, '/')}`;
  }
  return `img:svg/${name.replace(/_/g, '/')}.svg`;
};

const formatSvgIcon = (name: string) => {
  if (!name.includes('_')) {
    name = '_' + name
  }
  const symbolId = `#icon-${name}`;
  return `svguse:${symbolId}`;
};

const useSvgIcon = () => {
  return {
    formatSvgIconName: (name: string) => formatSvgIconName(name)
  };
};

export default useSvgIcon;

export type IconType = 'svg' | 'png'

export const setUpIconMapFn = () => {

  const quasar = useQuasar();

  // 添加对<q-icon name =“ app：....” />的
  // 支持的示例，
  // 其中包括对Quasar组件的
  // 所有“icon”属性的支持

  quasar.iconMapFn = (iconName) => {
    // iconName是QIcon“name”属性的内容

    // 您的自定义方法，以下
    // 仅是示例：
    if (iconName.startsWith('app:')) {
      // 去掉 "app:"
      const name = iconName.substring(4);

      return {
        icon: formatSvgIconName(name)
      };
    }
    if (iconName.startsWith('svg:')) {
      // console.log('svg:', iconName);
      // 去掉 "svg:"
      const name = iconName.substring(4);

      return {
        icon: formatSvgIcon(name)
      };
    }

    // 当我们不从iconMapFn返回任何内容时，
    // 默认的Quasar图标映射
    // 将接管
  };
};