<template>
  <router-view />
</template>

<script setup lang="ts">
import { setUpIconMapFn } from './hooks/web/useSvgIcon';
import Logger from '@/utils/Logger';

defineOptions({
  name: 'App'
});

// 添加icon映射
setUpIconMapFn();
Logger.prettyPrimary(`Welcome to`, import.meta.env.VITE_APP_TITLE)
</script>

<style scoped>

</style>