import request from '@/config/axios'

export interface UserVO {
  id: number
  createTime: number | undefined
  loginDate: number | undefined
  loginIp: string
  mark: string | undefined
  mobile: string
  name: string | undefined
  nickname: string | undefined
  registerIp: string
  status: number
  withdrawStatus: number | undefined
  bankStatus: number | undefined
  // 银行卡相关
  cardNo: string | undefined
  holderName: string | undefined
  bankId: number | undefined
  //
  payStatus: number | undefined
  payChannelCode: string | undefined
}


/**
 * 查询会员用户详情
  */
export const getUser = async () => {
  return await request.get({ url: '/member/user/get' })
}