// 62进制字符集
const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const BASE = CHARACTERS.length;

// 有缺陷，只能编码ascii码为2位的字符，不建议使用
export const encodeAscii2To62 = (str: string) => {
  // 将每个字符转换为ASCII值并拼接成字符串
  const asciiStr = str.split('').reduce((acc, char) => {
    return acc + char.charCodeAt(0).toString();
  }, '');

  // 将拼接后的字符串转换为BigInt
  let decimal = BigInt(asciiStr);

  // 定义62进制的字符集
  let result = '';

  // 使用BigInt进行循环直到decimal为0
  while (decimal > BigInt(0)) {
    // 使用BigInt进行取余运算
    const remainder = decimal % BigInt(BASE);
    // 将当前位的字符添加到结果字符串的前面
    result = CHARACTERS[Number(remainder)] + result;
    // 更新decimal为除以base后的商
    decimal = decimal / BigInt(BASE);
  }

  // 如果结果为空，返回'0'
  return result || '0';
};

/**
 * @description: 将字符串转换为62进制，区别于其他base62转换
 * @author Tsui
 * @param str 待编码的字符串
 * @return string 编码后的字符串
 */
export const encodeAscii3To62 = (str: string): string => {
  if (!str) {
    return '';
  }
  const base = BigInt(BASE);

  // 将每个字符转换为ASCII值并拼接成字符串
  const asciiStr = str.split('').reduce((acc, char) => {
    return acc + ('00' + char.charCodeAt(0)).slice(-3); // 确保每个字符的ASCII值都是3位
  }, '');

  // 将拼接后的字符串转换为BigInt
  let decimal = BigInt(asciiStr);

  let result = '';

  // 使用BigInt进行循环直到decimal为0
  while (decimal > BigInt(0)) {
    // 使用BigInt进行取余运算
    const remainder = decimal % base;
    // 将当前位的字符添加到结果字符串的前面
    result = CHARACTERS[Number(remainder)] + result;
    // 更新decimal为除以base后的商
    decimal = decimal / base;
  }

  // 如果结果为空，返回'0'
  return result || '';
};

/**
 * @description:
 * @author Tsui
 * @return string 解码后的字符串
 * @param encoded 上方62进制编码后的字符串
 */
export const decode62ToAscii3 = (encoded: string): string => {
  if (!encoded) {
    return '';
  }
  const base = BigInt(BASE);
  let decoded = BigInt(0);

  // 反转输入字符串以便于处理
  encoded = encoded.split('').reverse().join('');

  for (let i = 0; i < encoded.length; i++) {
    const c = encoded.charAt(i);
    const digit = CHARACTERS.indexOf(c);
    decoded += BigInt(digit) * (base ** BigInt(i));
  }

  let asciiString = '';
  let asciiValues = decoded.toString();

  switch (asciiValues.length % 3) {
    case 1:
      asciiValues = '00' + asciiValues;
      break;
    case 2:
      asciiValues = '0' + asciiValues;
      break;
  }

  for (let j = 0; j < asciiValues.length; j += 3) {
    const asciiValue = parseInt(asciiValues.substring(j, j + 3));
    asciiString += String.fromCharCode(asciiValue);
  }

  return asciiString || '';
};