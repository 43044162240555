<script setup lang="ts">

import { getCarouselByType } from '@/api/finance/manage/carousel';
import { propTypes } from '@/utils/propTypes';
import { object } from 'vue-types';
import { number, string } from 'vue-types';

defineOptions({
  name: 'CommonCarousel'
});

const props = defineProps({
  type: string().isRequired,
  ratio: number().def(7 / 3)
});

const slide = ref(0);
const imgList = ref<string[]>([]);

const getCarousel = async () => {
  imgList.value = (await getCarouselByType(props.type)).map(item => item.picUrl);
};

onMounted(() => {
  getCarousel();
});

</script>

<template>
  <!--轮播图-->
  <q-responsive v-if="imgList.length > 0" :ratio="ratio" class="q-pt-sm full-width">
    <!--    arrows-->
    <q-carousel
      v-model="slide"
      transition-prev="slide-right"
      transition-next="jump-left"
      animated
      swipeable
      autoplay
      navigation
      infinite
      keep-alive
      control-text-color="primary"
      class="common-carousel bg-transparent"
    >
      <!--          navigation-icon="radio_button_unchecked"-->
      <template v-slot:navigation-icon="{ active, btnProps, onClick }">
        <q-btn v-if="active" size="xs" :icon="btnProps?.icon"
               color="primary" flat round dense @click="onClick" />
        <q-btn v-else size="xs" :icon="btnProps?.icon"
               color="grey" flat round dense @click="onClick" />
      </template>

      <!--                        :img-src="src"-->
      <q-carousel-slide class="q-pa-none"
                        v-for="(src, index) in imgList" :key="index"
                        :name="index"
      >
        <q-img :src="src"
               fit="fill"
               height="100%">
          <template #loading>
            <div class="text-primary">
              <q-spinner-ios />
            </div>
          </template>
        </q-img>
      </q-carousel-slide>
    </q-carousel>
  </q-responsive>
</template>

<style lang="scss" scoped>
.common-carousel {
  :deep(.q-carousel__navigation--bottom) {
    bottom: 0;
  }

  :deep(.q-carousel__navigation .q-btn) {
    margin: 0;
    //padding: 0;
  }

  :deep(.q-btn .q-icon, .q-btn .q-spinner) {
    font-size: 1.35em;
  }
}
</style>