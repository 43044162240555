<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'LogOutDialog'
})

const { push, replace } = useRouter();
const { t } = useI18n();

const logout = ref(false);

const { loginOut } = useUserStore();

const handleLogOut = async () => {
  try {
    await loginOut();
    replace('/login');
    logout.value = false;
  } catch {
  }
};

const open = () => logout.value = true;

defineExpose({ open }) // 提供 open 方法，用于打开弹窗

</script>

<template>
<!--  可选 persistent 点击外部不关闭-->
  <q-dialog v-model="logout" full-width>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">{{ t('mine.logOutMessage') }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat :label="t('common.ok')" color="primary" @click="handleLogOut" />
        <q-btn flat :label="t('common.cancel')" color="grey" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>

</style>