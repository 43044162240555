<script setup lang="ts">

defineOptions({
  name: 'PromoteList'
});

import {
  getPromoterDownDetailPage,
  PromoterDetailPageVO,
  PromoterDetailVO
} from '@/api/finance/trading/member/promoter';
import PromoterDetailItem from './PromoterDetailItem.vue';

const props = defineProps({
  level: {
    type: Number,
    required: true
  }
});

const { t } = useI18n();
const { level } = props;

const promoterDetailList = ref<PromoterDetailVO[]>([]);

const getPromoterDetailList = async (pageNo: number, pageSize: number) => {
  return await getPromoterDownDetailPage({ level, pageNo, pageSize } as PromoterDetailPageVO);
};

const listRef = ref();
const reloadList = () => {
  listRef.value.reload();
};

onActivated(() => {
  reloadList();
});


</script>

<template>
  <div class="column col">
        <div class="row text-primary q-py-md">
          <q-separator vertical size="4px" color="primary" />
          <div v-text="t('myTeam.commission.commissionList')" class="q-pl-sm text-subtitle2 text-weight-bold" />
        </div>
    <q-list class="col-grow row promoter-list">
      <InfiniteLoad ref="listRef" :data-list="promoterDetailList" :load-func="getPromoterDetailList">
        <template #default="{ items }">
          <q-item v-for="(item, index) in items"
                  :key="index" class="q-px-none"
          >
            <PromoterDetailItem :item="item as PromoterDetailVO" @uploaded="reloadList" />
          </q-item>
        </template>
      </InfiniteLoad>
    </q-list>
  </div>
</template>

<style scoped>

</style>