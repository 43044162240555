/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/zh-CN.js'



import {QSpinnerRings,Notify,Dialog,Loading} from 'quasar'



const userOptions = { config: {"loading":{"delay":10,"messageColor":"primary","spinnerColor":"primary","spinner":"QSpinnerRings"}},lang,components: {QSpinnerRings},plugins: {Notify,Dialog,Loading} }
  
userOptions.config.loading.spinner = QSpinnerRings
  
  
export default userOptions

