<script setup lang="ts">

import { getHomeEditor, HomeEditorVO } from '@/api/finance/manage/homeEditor';

defineOptions({
  name: 'CompanyIntro'
});

const homeConfig = ref({} as HomeEditorVO);
const getHomeConfig = async () => {
  homeConfig.value = await getHomeEditor();
};

onMounted(() => {
  getHomeConfig();
});

</script>

<template>
  <div class="col-grow q-pa-md text-wrap-keep">
    <div class="company-intro" v-html="homeConfig.content" />
  </div>
</template>

<style lang="scss" scoped>
.company-intro {
  :deep(img) {
    width: 100% !important; /* 设置宽度为父元素宽度 */
    max-width: 800px;
    height: auto !important; /* 保持原始高度比 */
    object-fit: contain !important;
  }

}
</style>