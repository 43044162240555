import request from '@/config/axios'

export interface PromoterVO {
  id: number
  level: number | undefined | null
  levelLimit: number | undefined | null
}

export interface PromoterDetailVO {
  id: number // 用户id
  mobile: string // 手机号码
  level: number // 等级
  totalRecharge: number // 总充值金额
  totalCommission: number // 总佣金
  createTime: Date // 日期
}

export interface PromoterDetailPageVO extends PageParam{
  level: number // 等级
}

// 查询下级分销用户列表
export const getPromoterDownPage = async (params: PageParam) => {
  return await request.get({ url: `/member/promoter/down/page`, params })
}

// 查询上级分销用户列表
export const getPromoterUpPage = async (params: PageParam) => {
  return await request.get({ url: `/member/promoter/up/page`, params })
}

// 查询 有效推广/推广数量
export const getDownPromoteNumber = async () => {
  return await request.get({ url: '/member/promoter/down/number' })
}

// 查询下级分销用户详细列表
export const getPromoterDownDetailPage = async (params: PageParam) => {
  return await request.get({ url: `/member/promoter/downDetail/page`, params })
}