import request from '@/config/axios'
import { getRefreshToken } from '@/utils/auth'
import type { UserLoginVO, UserRegisterVO } from './types';


// 注册
export const register = async (data: UserRegisterVO) => {
  return await request.post({ url: `/member/auth/register`, data })
}

// 登录
export const login = async (data: UserLoginVO) => {
  return await request.post({ url: `/member/auth/login`, data })
}

// 刷新访问令牌
export const refreshToken = () => {
  return request.post({ url: '/member/auth/refresh-token?refreshToken=' + getRefreshToken() })
}

// 登出
export const loginOut = () => {
  return request.post({ url: '/member/auth/logout' })
}

// 使用租户名，获得租户编号
export const getTenantIdByName = (name: string) => {
  return request.get({ url: '/system/tenant/get-id-by-name?name=' + name })
}

// 使用租户域名，获得租户信息
export const getTenantByWebsite = (website: string) => {
  return request.get({ url: '/system/tenant/get-by-website?website=' + website })
}

// 获取验证图片以及 token
export const getCode = (data) => {
  return request.postOriginal({ url: 'system/captcha/get', data })
}

// 滑动或者点选验证
export const reqCheck = (data) => {
  return request.postOriginal({ url: 'system/captcha/check', data })
}