<script setup lang="ts">

import { delay } from '@/utils';

defineOptions({
  name: 'WithdrawBtn'
});

import WithdrawForm from './WithdrawForm.vue';
import { QNotify } from '@/utils/QNotify';

const { t } = useI18n();
const showWithdraw = ref(false);

const loading = ref(false);

const handleWithdraw = async (withdrawAmount) => {
  showWithdraw.value = false;
};


</script>

<template>
  <div class="row">
    <q-btn no-caps unelevated class="col radius-sm full-height bg-white" @click="showWithdraw = true">
      <div class="q-py-sm column items-center">
        <q-icon name="receipt_long" size="2rem" class="text-primary" />
        <div v-text="t('withdraw.withdraw')" />
      </div>
    </q-btn>
    <q-dialog v-model="showWithdraw" full-width>
      <q-card class="radius-sm relative-position">
        <q-card-section class="row justify-end q-pb-none">
          <!--          <div class="text-body2" v-text="t('bank.cardInfo')" />-->
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <WithdrawForm @success="handleWithdraw" />
        </q-card-section>
        <q-inner-loading :showing="loading">
          <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>

</style>