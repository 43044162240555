<script setup lang="ts">

defineOptions({
  name: 'OrderList'
});

import OrderItem from './OrderItem.vue';
import { getOrderPage, OrderItemVO } from '@/api/finance/trading/order';

const { currentRoute } = useRouter();
const listRef = ref();
const orderList = ref<OrderItemVO[]>([]);

const onLoadList = async (pageNo: number, pageSize: number) => {
  const res = await getOrderPage({
    // status: 20, // 0待支付 10已取消 20手动完成 30已完成
    pageNo,
    pageSize
  })
  return res;
};

// watch(
//   () => currentRoute.value,
//   () => {
//     listRef.value.reload();
//   }
// )

onActivated(() => {
  listRef.value.reload();
})

</script>

<template>
  <q-list
    class="col-grow q-pt-md row order-list">
    <InfiniteLoad ref="listRef" :data-list="orderList" :load-func="onLoadList">
      <template #default="{ items }">
        <q-item
          v-for="(item, index) in items"
          :key="index" class="q-px-none"
        >
          <OrderItem :item="item as OrderItemVO" />
        </q-item>
      </template>
    </InfiniteLoad>
  </q-list>
</template>

<style scoped>

</style>