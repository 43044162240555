import request from '@/config/axios'

export interface BankVO {
  id: number
  bankName: string
}

// 查询列表
export const getBankList = () => {
  return request.get<BankVO>({ url: '/pay/bank/get-simple-list' })
}