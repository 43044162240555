import request from '@/config/axios'

export interface WithdrawVO {
  id: number | null
  no: string | null
  price: number
  status: number
  createTime: Date
}

// 查询交易订单详情
export const getWithdrawPage = async (params: PageParam) => {
  return await request.get({ url: `/trade/withdraw/page`, params })
}

// 查询交易订单详情
export const getWithdraw = async (id: number | null) => {
  return await request.get({ url: `/trade/withdraw/get-detail?id=` + id })
}

// 创建订单
export const createWithdraw = async (data) => {
  return await request.post({ url: `/trade/withdraw/create`, data})
}