<script setup lang="ts">

defineOptions({
  name: 'Carousel'
});

const props = defineProps({
  srcList: {
    type: Array<string>,
    required: true
  }
});

const slide = ref(0);
const { srcList } = toRefs(props);

</script>

<template>
  <!--    arrows-->
  <q-carousel
    v-model="slide"
    transition-prev="slide-right"
    transition-next="slide-left"
    animated
    swipeable
    autoplay
    navigation
    infinite
    keep-alive
    control-text-color="primary"
    class="img-carousel bg-transparent"
  >
    <!--          navigation-icon="radio_button_unchecked"-->
    <template v-slot:navigation-icon="{ active, btnProps, onClick }">
      <q-btn v-if="active" size="xs" :icon="btnProps?.icon"
             color="primary" flat round dense @click="onClick" />
      <q-btn v-else size="xs" :icon="btnProps?.icon"
             color="grey" flat round dense @click="onClick" />
    </template>

    <!--                        :img-src="src"-->
    <q-carousel-slide class="q-pa-none"
                      v-for="(src, index) in srcList" :key="index"
                      :name="index"
    >
      <q-img :src="src"
             fit="fill"
             height="100%">
        <template #loading>
          <div class="text-primary">
            <q-spinner-ios />
          </div>
        </template>
      </q-img>
    </q-carousel-slide>
  </q-carousel>
</template>

<style lang="scss" scoped>
.img-carousel {
  :deep(.q-carousel__navigation--bottom) {
    bottom: 0;
  }

  :deep(.q-carousel__navigation .q-btn) {
    margin: 0;
    //padding: 0;
  }

  :deep(.q-btn .q-icon, .q-btn .q-spinner) {
    font-size: 1.35em;
  }
}
</style>