import enUS from './en-US';
import zhCN from './zh-CN';
import ruRU from './ru-RU';
import frFR from './fr-FR';

export default {
  'en-US': enUS,
  'zh-CN': zhCN,
  'ru-RU': ruRU,
  'fr-FR': frFR,
};