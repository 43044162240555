import request from '@/config/axios'

export interface Spu {
	id: number
	name: string // 商品名称
	picUrl: string // 商品封面图
	sliderPicUrls: string[] // 商品轮播图
	introduction: string // 商品简介
	description: string // 商品详情
	sort: number // 商品排序
	virtualSalesCount?: number // 虚拟销量
	price: number // 商品价格
	salesCount: number // 商品销量
	stock: number // 商品库存
	createTime?: Date // 商品创建时间
	status?: number // 商品状态

	dailyReturnRate: number // 日收益率
	totalReturnRate: number // 总收益率
	totalPeriod: number // 总周期
	dailyIncome: number // 日收益
	totalIncome: number // 总收益
}

// 获得 Spu 列表(只返回上架状态的)
export const getSpuList = () => {
	return request.get({url: '/product/spu/list-all'})
}

// 获得商品 Spu 详细信息
export const getSpuDetail = (id: number) => {
	return request.get({url: `/product/spu/get-detail?id=${id}`})
}