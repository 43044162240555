import request from '@/config/axios'

export interface ChannelVO {
  id: number
  code: string
  name: string
  region: string
  config: string
  status: number
  remark: string
  feeRate: number
  appId: number
  createTime: Date
}


// 查询列表支付渠道
export const getChannelList = () => {
  return request.get<ChannelVO[]>({ url: '/pay/channel/get-enable-channel-list' })
}