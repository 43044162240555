<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';
import LevelProfitCard from './LevelProfitCard.vue';
import { getDownPromoteNumber } from '@/api/finance/trading/member/promoter';

defineOptions({
  name: 'TeamInfo'
});

const { getProfitRate } = toRefs(useConfigStore());

const profitRate = computed(() => {
  return getProfitRate.value.map(rate => parseInt(rate));
});

const promoteNumber = ref({});

const getLevelPromoteNumberMap = async () => {
  promoteNumber.value = await getDownPromoteNumber();
};

onActivated(() => {
  getLevelPromoteNumberMap();
});

// 不同level使用不同的背景色
const COLOR_LIST = [
  // 'bg-pink-2',
  // 'bg-deep-purple-2',
  'bg-amber-3',
  'bg-blue-grey-2',
  'bg-brown-3'
];
const bgColor = (level: number) => {
  return COLOR_LIST[level] || 'bg-grey-3';
};

</script>

<template>
  <q-list>
    <q-item v-for="(rate, index) in profitRate"
            :key="index" class="q-px-none"
    >
      <LevelProfitCard
        :class="bgColor(index)"
        :level="index + 1"
        :effective-count="promoteNumber[index + 1]?.effectivePromoteCount || 0"
        :promote-count="promoteNumber[index + 1]?.promoteCount || 0"
        :rate="rate"
      />
    </q-item>
  </q-list>
</template>

<style scoped>

</style>