import request from '@/config/axios'

export interface CarouselVO {
	picUrl: string
	// sort: number | null
}

// 查询faq的轮播图
export const getFaqCarousel = async () => {
	return await request.get<CarouselVO[]>({ url: '/manage/carousel/get/faq' })
}

// 查询share的轮播图
export const getShareCarousel = async () => {
	return await request.get<CarouselVO[]>({ url: '/manage/carousel/get/share' })
}

// 查询home的轮播图
export const getHomeCarousel = async () => {
	return await request.get<CarouselVO[]>({ url: '/manage/carousel/get/home' })
}

// 查询typr的轮播图
export const getCarouselByType = async (type: string) => {
	return await request.get<CarouselVO[]>({ url: '/manage/carousel/get/' + type })
}