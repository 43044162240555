<script setup lang="ts">

import { formatToDateTime } from '@/utils/dateUtil';

defineOptions({
  name: 'WithdrawItem'
});

import { object } from 'vue-types';
import { fenToYuan, processPriceUnit } from '@/utils/price';
import { WithdrawVO } from '@/api/finance/trading/withdraw';

const { t } = useI18n();

const props = defineProps({
  item: object<WithdrawVO>().isRequired
});

const withdraw = ref<WithdrawVO>(props.item);

const WithdrawStatus = {
  0:  'auditing',
  10: 'approved',
  11: 'success',
  20: 'audit failed',
  21: 'withdraw failed'
};

const withdrawStatus = computed(() => {
  return WithdrawStatus[withdraw.value.status];
});

const no = computed(() => {
  return formatToDateTime(withdraw.value.createTime, 'YYYYMMDDHHmmss');
});

const price = computed(() => {
  return fenToYuan(withdraw.value.price!);
});

const createTime = computed(() => {
  return formatToDateTime(withdraw.value.createTime)
})

</script>

<template>
  <q-card class="no-shadow radius-sm full-width text-caption">
    <!--订单号-->
    <q-card-section class="q-pa-sm q-px-md row justify-between text-right text-white text-weight-medium"
                    :class="
                    withdrawStatus === 'success' ? 'bg-positive' :
                    withdrawStatus === 'audit failed' ? 'bg-negative' :
                    withdrawStatus === 'withdraw failed' ? 'bg-negative' :
                    'bg-grey-6'">
      <div class="col-9 text-left ellipsis">
        {{ t('withdraw.withdrawNo') }}: {{  withdraw.no }}
      </div>
      <q-space />
<!--      <div class="col-9 text-left ellipsis">-->
<!--        {{ t('withdraw.withdrawNo') }}: W{{ withdraw.id }}-->
<!--      </div>-->
<!--      <q-space />-->
      <div class="col text-weight-bold">{{ t('withdraw.withdrawStatus.' + withdrawStatus) }}</div>
    </q-card-section>
    <!--详细信息-->
    <q-card-section class="row">
      <div class="col column">
        <div class="text-subtitle2 text-weight-bolder">
          {{ t('withdraw.price') }}: <span class="text-primary">{{ processPriceUnit(price) }}</span>
        </div>
        <div>
          {{ createTime }}
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<style scoped>

</style>