import request from '@/config/axios'

export interface FaqVO {
	question: string
	answer: string
	// sort: number | null
}

// 查询faq列表
export const getFaqList = async () => {
	return await request.get<FaqVO[]>({ url: '/manage/faq/get-list' })
}

export const getFaq = async (id: number) => {
	return await request.get<FaqVO>({ url: '/manage/faq/get?id=' + id })
}