import request from '@/config/axios'

export interface ConfigVO {
	// ============= 用户配置 =============
	phoneAreaCode: number | null
	phoneDigit: number | null
	// ============= 支付配置 =============
	minWithdrawNum: number
	minWithdrawNumYuan : number
	maxWithdrawNum: number
	maxWithdrawNumYuan: number
	// ============= 交易配置 =============
	// ============= 分润配置 =============
	promotionOrderProfitRate: string
	// ============= 其他配置 =============
	nationName: string | null
	currencySymbol: string
	currencyCode: string | null
	decimalNum: number | null
	officialUrl: string | null
}

// 查询配置
export const getConfig = async () => {
	return await request.get<ConfigVO>({ url: '/manage/config/get' })
}