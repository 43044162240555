<script setup lang="ts">

defineOptions({
  name: 'RecordDesc'
})

const { t } = useI18n()

</script>

<template>
<div class="radius-sm q-pa-md desc-box" v-text="t('paymentRecord.remark')" />
</template>

<style lang="scss" scoped>

.desc-box {
  border: 1px solid $primary;
}
</style>