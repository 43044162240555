import { defineStore } from 'pinia';
import { pinia } from './index';
import { fenToYuan } from '@/utils/price';
import { getUser } from '@/api/finance/trading/member/user';
import { BankInfoVO, getWallet, getBankInfo, setBankInfo, WalletExtraVO } from '@/api/finance/trading/pay/wallet';
import { removeToken } from '@/utils/auth';
import { loginOut } from '@/api/login';
import { encodeAscii3To62 } from '@/utils/string62Converter';
import { useChannelStore } from './channel';

interface UserVO {
  id: number;
  name: string;
  nickname: string;
  mobile: string;
  balance: number;
  cardNo: string;
  holderName: string;
  bankId: number;
  walletExtra: WalletExtraVO;
  isSetUser: boolean;
}

export const useUser = defineStore('app-user', {
  state: (): UserVO => ({
    id: 0,
    name: '',
    nickname: '',
    mobile: '',
    balance: 0,
    holderName: '',
    bankId: 0,
    cardNo: '',
    walletExtra: {} as WalletExtraVO,
    isSetUser: false
  }),

  getters: {
    getId(): number {
      return this.id;
    },
    getId62(): string {
      const randomNumber = Math.floor(Math.random() * 100);
      return encodeAscii3To62(randomNumber + '!' + this.id.toString());
    },
    getMobile(): string {
      return this.mobile;
    },
    getBankInfo(): BankInfoVO {
      return {
        holderName: this.holderName,
        cardNo: this.cardNo,
        bankId: this.bankId,
        walletExtra: this.walletExtra
      };
    },
    getBalance(): number {
      return this.balance;
    },
    doubleCount(): number {
      return this.balance * 2;
    },
    isBankNotOk(): boolean {
      const base = !this.holderName || !this.cardNo || !this.bankId;
      const { hasIndian } = toRefs(useChannelStore())
      if (hasIndian.value) {
        return base || !this.walletExtra.ifsc;
      }
      return base;
    }
  },

  actions: {
    incrementBalance() {
      this.balance++;
    },
    decrementBalance() {
      this.balance--;
    },
    reset(): void {
      this.isSetUser = false;
    },
    async setBankInfo(data: BankInfoVO): Promise<void> {
      const bankData = {
        holderName: data.holderName || '',
        cardNo: data.cardNo || '',
        bankId: data.bankId || 0
      } as BankInfoVO;
      if (data.walletExtra) {
        bankData.walletExtra = data.walletExtra;
      }
      await setBankInfo(bankData);
      await this.updateBankInfo();
    },
    async updateBankInfo(): Promise<void> {
      const bankInfoVO = await getBankInfo();
      this.holderName = bankInfoVO.holderName;
      this.cardNo = bankInfoVO.cardNo;
      this.bankId = bankInfoVO.bankId;
      this.walletExtra = bankInfoVO.walletExtra;
    },
    async updateUserInfo(): Promise<void> {
      const user = await getUser();
      this.isSetUser = true;
      this.id = user.id;
      this.name = user.name;
      this.nickname = user.nickname;
      this.mobile = user.mobile;
    },
    async updateBalance(): Promise<void> {
      const walletVO = await getWallet();
      this.balance = fenToYuan(walletVO.balance);
    },
    async loginOut() {
      await loginOut();
      removeToken();
      // this.reset()
    }
  }
});

export const useUserStore = () => {
  return useUser(pinia);
};