<script setup lang="ts">

defineOptions({
  name: 'PromoterDetailItem'
});

import { object } from 'vue-types';
import { PromoterDetailVO } from '@/api/finance/trading/member/promoter';
import { formatToDateTime } from '@/utils/dateUtil';
import { fenToYuan, processPriceUnit } from '@/utils/price';

const { t } = useI18n();

const props = defineProps({
  item: object<PromoterDetailVO>().isRequired
});

const promoterDetail = ref<PromoterDetailVO>(props.item);

// const hidedMobile = computed(() => {
//   const originMobile = promoterDetail.value.mobile;
//   // originMobile从第四位开始隐藏4个数字
//   const hidedMobile = originMobile.replace(originMobile.substring(3, 7), '****');
//   console.log(hidedMobile);
//   return hidedMobile;
// });

// 总充值
const totalRechargeYuan = computed(() => {
  return fenToYuan(promoterDetail.value.totalRecharge);
});

// 我的佣金
const myCommissionYuan = computed(() => {
  return fenToYuan(promoterDetail.value.totalCommission);
});

const createTime = computed(() => {
  return formatToDateTime(promoterDetail.value.createTime);
});

</script>

<template>
  <q-card class="no-shadow radius-sm full-width text-caption text-weight-bold">
    <!--用户id 和 时间 -->
    <q-card-section class="q-pa-sm row items-center text-caption text-weight-medium">
      <div class="col">
        ID: {{ promoterDetail.mobile }}
      </div>
      <q-space />
      <div class="col text-right">
        {{ createTime }}
      </div>
    </q-card-section>
    <q-separator />
    <!--充值和佣金-->
    <q-card-section class="row q-pa-none text-caption text-weight-bold">
      <div class="col q-py-sm column items-center">
        <div class="text-body2 text-weight-bold text-primary">
          {{ processPriceUnit(totalRechargeYuan) }}
        </div>
        <div>{{ t('myTeam.commission.totalRecharge') }}</div>
      </div>
      <q-separator vertical />
      <div class="col q-py-sm column items-center">
        <div class="text-body2 text-weight-bold text-primary">
          {{ processPriceUnit(myCommissionYuan) }}
        </div>
        <div>{{ t('myTeam.commission.myCommission') }}</div>
      </div>
    </q-card-section>
  </q-card>
</template>

<style scoped>

</style>