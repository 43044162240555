<script setup lang="ts">

import { useUserStore } from '@/stores/user';
import { QNotify } from '@/utils/QNotify';
import { processPriceUnit, yuanToFen } from '@/utils/price';
import { QLoading } from '@/utils/QLoading';
import { createWithdraw } from '@/api/finance/trading/withdraw';
import { EmitType, useEmitt } from '@/hooks/web/useEmitt';
import { useConfigStore } from '@/stores/manageConfig';

defineOptions({
  name: 'WithdrawForm'
});

const emit = defineEmits(['success']);

const { t } = useI18n();
const userStore = useUserStore();
const { getConfig } = toRefs(useConfigStore());
const { emitter } = useEmitt()

const { updateBalance } = userStore;

const withdrawAmount = ref();

const minAmount = getConfig.value.minWithdrawNumYuan;

const formRules = {
  withdrawAmount: [
    val => !!val || t('withdraw.rules.noWithdrawAmountError'),
    val => val >= minAmount || t('withdraw.rules.withdrawAmountError')
  ]
};

const { isBankNotOk } = toRefs(useUserStore())

const handleSubmit = async () => {
  if (isBankNotOk.value) {
    QNotify.error(t('withdraw.bankNotOk'))
    return
  }
  QLoading.show({
    message: t('common.processing')
  });
  try {
    // 申请提现
    const price = yuanToFen(withdrawAmount.value)
    await createWithdraw({price});
    // 提现-事件
    emitter.emit(EmitType.withdrawn)
    await updateBalance()
    QNotify.success(t('common.success'));
  } finally {
    emit('success', withdrawAmount);
    QLoading.hide();
  }
};

</script>

<template>
  <q-form class="q-gutter-sm" ref="bankFormRef" @submit="handleSubmit">
    <!-- 提现金额-->
    <div class="q-px-sm q-mt-none">
      <div class="q-pa-sm text-weight-bold">{{ t('withdraw.withdrawAmount') }}</div>
      <!--               standout="bg-grey-4 text-black" -->
      <q-input v-model="withdrawAmount" dense
               :placeholder="t('withdraw.withdrawAmountPlaceholder')"
               outlined clearable type="number"
               :rules="formRules.withdrawAmount"
      >
      </q-input>
    </div>
<!--    <q-separator class="q-mb-xs" />-->
    <!--说明-->
    <div class="column items-center text-weight-bold ellipsis">
      <!-- TODO 后续需要根据配置来-->
      <div class="col q-py-xs text-body2 text-weight-bold text-primary" v-text="t('withdraw.aboutWithdraw')"></div>
      <div class="col" v-text="t('withdraw.processTime') + ': 7x24h'"></div>
      <div class="col" v-text="t('withdraw.minimumAmount') +': '+ processPriceUnit(minAmount)"></div>
      <div class="col" v-text="t('withdraw.arrivalTime') + ': Within 5 Mins'"></div>
      <div class="col" v-text="t('withdraw.fees') + ': None'"></div>
    </div>

    <!-- 确认按钮-->
    <div class="q-px-sm column login-button">
      <q-btn class="q-py-sm q-py-sm" :label="t('withdraw.withdrawBtn')"
             color="primary" type="submit"
             rounded noCaps
      />
    </div>
  </q-form>
</template>

<style scoped>

</style>