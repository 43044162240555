<script setup lang="ts">

import { ComputedRef } from 'vue';

defineOptions({
  name: 'FaqCard'
});

import { object } from 'vue-types';
import { FaqVO } from '@/api/finance/manage/faq';

const props = defineProps({
  item: object<FaqVO>().isRequired
})

const faq = computed(() => props.item as FaqVO)

</script>

<template>
  <q-expansion-item
    class="bg-white overflow-hidden radius-sm"
    icon="app:mine_FAQ"
    :label="faq.question"
  >
    <q-separator />
    <q-card>
      <q-card-section>
        {{ faq.answer }}
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<style scoped>

</style>