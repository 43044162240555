export default {
  failed: 'Action échouée',
  success: 'Action réussie',
  common: {
    inputText: 'Veuillez saisir',
    selectText: 'Veuillez sélectionner',
    startTimeText: 'Heure de début',
    endTimeText: 'Heure de fin',
    login: 'Connexion',
    required: 'Ce champ est requis',
    loginOut: 'Déconnexion',
    document: 'Document',
    profile: 'Centre utilisateur',
    reminder: 'Rappel',
    loginOutMessage: 'Quitter le système ?',
    back: 'Retour',
    ok: 'OK',
    save: 'Enregistrer',
    cancel: 'Annuler',
    close: 'Fermer',
    reload: 'Recharger la page',
    success: 'Succès',
    closeTab: 'Fermer l’onglet actuel',
    closeTheLeftTab: 'Fermer à gauche',
    closeTheRightTab: 'Fermer à droite',
    closeOther: 'Fermer les autres',
    closeAll: 'Fermer tout',
    prevLabel: 'Précédent',
    nextLabel: 'Suivant',
    skipLabel: 'Sauter',
    doneLabel: 'Terminer',
    menu: 'Menu',
    menuDes: 'Barre de menu rendue dans une structure de routage',
    collapse: 'Réduire',
    collapseDes: 'Réduire et agrandir la barre de menu',
    tagsView: 'Vue des étiquettes',
    tagsViewDes: 'Utilisé pour enregistrer l’historique de routage',
    tool: 'Outil',
    toolDes: 'Utilisé pour configurer le système personnalisé',
    query: 'Requête',
    reset: 'Réinitialiser',
    shrink: 'Réduire',
    expand: 'Étendre',
    confirmTitle: 'Indication du système',
    exportMessage: 'Confirmez-vous l’exportation de l’élément de données ?',
    importMessage: 'Confirmez-vous l’importation de l’élément de données ?',
    createSuccess: 'Création réussie',
    updateSuccess: 'Mise à jour réussie',
    delMessage: 'Supprimer les données sélectionnées ?',
    delDataMessage: 'Supprimer les données ?',
    delNoData: 'Veuillez sélectionner les données à supprimer',
    delSuccess: 'Suppression réussie',
    index: 'Index',
    status: 'Statut',
    createTime: 'Heure de création',
    updateTime: 'Heure de mise à jour',
    copy: 'Copier',
    copySuccess: 'Copie réussie',
    copyError: 'Erreur de copie',
    processing: 'Traitement...',
    submit: 'Soumettre'
  },
  error: {
    noPermission: `Désolé, vous n’avez pas l’autorisation d’accéder à cette page.`,
    pageError: 'Désolé, la page que vous avez visitée n’existe pas.',
    networkError: 'Désolé, le serveur a signalé une erreur.',
    returnToHome: 'Retour à l’accueil'
  },
  login: {
    welcome: 'Bienvenue',
    message: 'Système de gestion backend',
    tenantName: 'Nom du locataire',
    username: 'Nom d’utilisateur',
    password: 'Mot de passe',
    code: 'code de vérification',
    login: 'Se connecter',
    toSignUp: 'Créer un nouveau compte ? ',
    signUp: 'S’inscrire',
    toLogin: 'Déjà inscrit ? ',
    reLogin: 'Se reconnecter',
    register: 'S’inscrire',
    checkPassword: 'Confirmer le mot de passe',
    remember: 'Se souvenir de moi',
    hasUser: 'Compte existant ? Aller à la connexion',
    forgetPassword: 'Mot de passe oublié ?',
    usernamePlaceholder: 'Veuillez entrer le nom d’utilisateur',
    phoneNumberPlaceholder: 'Veuillez entrer le numéro de téléphone',
    passwordPlaceholder: 'Veuillez entrer le mot de passe',
    codePlaceholder: 'Veuillez entrer le code de vérification',
    phoneNumber: 'Numéro de téléphone',
    backLogin: 'retour',
    loginBtn: 'Se connecter',
    registerBtn: 'S’inscrire',
    // 校验相关
    rules: {
      noPhoneError: 'Le numéro de téléphone est requis',
      noPasswordError: 'Le mot de passe est requis',
      phoneNumberError: 'Veuillez entrer un numéro de téléphone valide',
      passwordError: 'Le mot de passe doit contenir entre 6 et 20 caractères'
    }
  },
  captcha: {
    verification: 'Veuillez compléter la vérification de sécurité',
    slide: 'Glissez vers la droite pour terminer la vérification',
    point: 'Veuillez cliquer',
    success: 'Vérification réussie',
    fail: 'Échec de la vérification'
  },
  router: {
    login: 'Connexion',
    home: 'Accueil',
    product: 'Produit',
    wallet: 'Portefeuille',
    bankAccount: 'Compte bancaire',
    order: 'Commande',
    mine: 'Mon profil',
    productDetail: 'Détails du produit',
    paymentRecords: 'Historique des paiements',
    myTeam: 'Mon équipe',
    payment: 'Paiement',
    paymentRecord: 'Historique des paiements',
    aboutUs: 'À propos de nous',
    faq: 'FAQ',
    share: 'Partager'
  },
  form: {
    input: 'Saisie',
    inputNumber: 'Saisie numérique',
    default: 'Par défaut',
    icon: 'Icône',
    mixed: 'Mixte',
    textarea: 'Zone de texte',
    slot: 'Emplacement',
    position: 'Position',
    autocomplete: 'Autocomplétion',
    select: 'Sélection',
    selectGroup: 'Groupe de sélection',
    selectV2: 'Sélection V2',
    cascader: 'Cascade',
    switch: 'Interrupteur',
    rate: 'Évaluation',
    colorPicker: 'Sélecteur de couleur',
    transfer: 'Transfert',
    render: 'Rendu',
    radio: 'Bouton radio',
    button: 'Bouton',
    checkbox: 'Case à cocher',
    slider: 'Curseur',
    datePicker: 'Sélecteur de date',
    shortcuts: 'Raccourcis',
    today: 'Aujourd’hui',
    yesterday: 'Hier',
    aWeekAgo: 'Il y a une semaine',
    week: 'Semaine',
    year: 'Année',
    month: 'Mois',
    dates: 'Dates',
    daterange: 'Plage de dates',
    monthrange: 'Plage de mois',
    dateTimePicker: 'Sélecteur de date et heure',
    dateTimerange: 'Plage de date et heure',
    timePicker: 'Sélecteur de temps',
    timeSelect: 'Sélection de temps',
    inputPassword: 'Entrer le mot de passe',
    passwordStrength: 'Force du mot de passe',
    operate: 'Opérer',
    change: 'Modifier',
    restore: 'Restaurer',
    disabled: 'Désactivé',
    disablement: 'Désactivation',
    delete: 'Supprimer',
    add: 'Ajouter',
    setValue: 'Définir la valeur',
    resetValue: 'Réinitialiser la valeur',
    set: 'Définir',
    subitem: 'Sous-élément',
    formValidation: 'Validation du formulaire',
    verifyReset: 'Vérifier la réinitialisation',
    remark: 'Remarque'
  },
  watermark: {
    watermark: 'Marque d’eau'
  },
  dialog: {
    dialog: 'Dialogue',
    open: 'Ouvrir',
    close: 'Fermer'
  },
  sys: {
    api: {
      operationFailed: 'Opération échouée',
      errorTip: 'Indication d’erreur',
      errorMessage: 'L’opération a échoué, le système est anormal !',
      timeoutMessage: 'La session de connexion a expiré, veuillez vous reconnecter !',
      apiTimeoutMessage: 'La requête d’interface a expiré, veuillez actualiser la page et réessayer !',
      apiRequestFailed: 'La requête d’interface a échoué, veuillez réessayer plus tard !',
      networkException: 'anomalie réseau',
      networkExceptionMsg: 'Veuillez vérifier si votre connexion réseau est normale ! Le réseau est anormal',
      errMsg401: 'L’utilisateur n’a pas les autorisations nécessaires (erreur de token, nom d’utilisateur, mot de passe) !',
      errMsg403: 'L’utilisateur est autorisé, mais l’accès est interdit !',
      errMsg404: 'Erreur de requête réseau, la ressource n’a pas été trouvée !',
      errMsg405: 'Erreur de requête réseau, méthode de requête non autorisée !',
      errMsg408: 'La requête réseau a expiré !',
      errMsg500: 'Erreur serveur, veuillez contacter l’administrateur !',
      errMsg501: 'Le réseau n’est pas implémenté !',
      errMsg502: 'Erreur réseau !',
      errMsg503: 'Le service est indisponible, le serveur est temporairement surchargé ou en maintenance !',
      errMsg504: 'Temps d’attente réseau dépassé !',
      errMsg505: 'La version HTTP ne prend pas en charge la requête !',
      errMsg901: 'Mode démo, aucune opération d’écriture n’est possible !'
    },
    app: {
      logoutTip: 'Indication',
      logoutMessage: 'Confirmez-vous la sortie du système ?',
      menuLoading: 'Chargement du menu...'
    },
    exception: {
      backLogin: 'Retour à la connexion',
      backHome: 'Retour à l’accueil',
      subTitle403: 'Désolé, vous n’avez pas accès à cette page.',
      subTitle404: 'Désolé, la page que vous avez visitée n’existe pas.',
      subTitle500: 'Désolé, le serveur signale une erreur.',
      noDataTitle: 'Aucune donnée sur la page actuelle.',
      networkErrorTitle: 'Erreur réseau',
      networkErrorSubTitle: 'Désolé, votre connexion réseau a été interrompue, veuillez vérifier votre réseau !'
    }
  },
  profile: {
    user: {
      title: 'Informations personnelles',
      username: 'Nom d’utilisateur',
      nickname: 'Surnom',
      mobile: 'Numéro de téléphone',
      email: 'Adresse e-mail',
      dept: 'Département',
      posts: 'Poste',
      roles: 'Rôle',
      sex: 'Sexe',
      man: 'Homme',
      woman: 'Femme',
      createTime: 'Date de création'
    },
    info: {
      title: 'Informations de base',
      basicInfo: 'Informations de base',
      resetPwd: 'Réinitialiser le mot de passe',
      userSocial: 'Informations sociales'
    },
    rules: {
      nickname: 'Veuillez entrer le surnom de l’utilisateur',
      mail: 'Veuillez saisir l’adresse e-mail',
      truemail: 'Veuillez saisir une adresse e-mail valide',
      phone: 'Veuillez entrer le numéro de téléphone',
      truephone: 'Veuillez entrer un numéro de téléphone valide'
    },
    password: {
      oldPassword: 'Ancien mot de passe',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmer le mot de passe',
      oldPwdMsg: 'Veuillez entrer l’ancien mot de passe',
      newPwdMsg: 'Veuillez entrer le nouveau mot de passe',
      cfPwdMsg: 'Veuillez confirmer le mot de passe',
      pwdRules: 'Les mots de passe doivent contenir entre 6 et 20 caractères',
      diffPwd: 'Les mots de passe entrés deux fois ne correspondent pas'
    }
  },
  user: {
    balance: 'Solde',
    totalIncome: 'Revenu total',
    todayIncome: 'Revenu du jour',
    totalCommission: 'Commission totale',
    todayCommission: 'Commission du jour',
    paymentRecords: 'Historique des paiements'
  },
  product: {
    dailyIncome: 'Revenu quotidien',
    totalIncome: 'Revenu total',
    buy: 'Acheter',
    placeAnOrder: 'Passer une commande',
    price: 'Prix',
    serving: 'Service',
    day: 'jour',
    days: 'jours',
    viewDetail: 'Voir & Acheter >',
    investmentPlans: 'Plans d’investissement',
    investmentPlansInfo: 'Sélectionnez un plan emballé ci-dessous pour commencer votre parcours de gains. Cliquez dessus pour agrandir l’image et afficher des informations supplémentaires sur le produit.',
  },
  order: {
    dailyIncome: 'Revenu quotidien',
    totalIncome: 'Revenu total',
    alreadyObtained: 'Déjà obtenu',
    servingTime: 'Durée de service',
    day: 'jour',
    days: 'jours',
    dailyReturnTime: 'Heure de retour quotidien',
    nextReturnTime: 'Prochain temps de gain',
    price: 'Prix',
    orderNotPaid: 'Non payé',
  },
  bank: {
    card: 'Carte',
    cardInfo: 'Informations de carte',
    holderName: 'Nom du titulaire du compte',
    bankName: 'Nom de la banque',
    cardNo: 'Numéro de compte',
    ifsc: 'IFSC',
    holderNamePlaceholder: 'Veuillez entrer votre nom complet',
    cardNoPlaceholder: 'Veuillez entrer votre numéro de compte',
    ifscPlaceholder: 'Veuillez entrer l’IFSC',
    rules: {
      noHolderNameError: 'Le nom du titulaire du compte est requis',
      noBankIdError: 'Veuillez sélectionner le nom de la banque',
      noCardNoError: 'Le numéro de compte est requis',
      noIfscError: 'L’IFSC est requis',
      ifscError: 'Veuillez entrer un IFSC correct',
    },
    remark: 'Veuillez saisir les informations du compte bancaire avec précision. Nous ne serons pas responsables des pertes financières causées par des erreurs d’information.'
  },
  withdraw: {
    withdraw: 'Retrait',
    withdrawRecord: 'Historique des retraits',
    withdrawAmount: 'Montant du retrait',
    withdrawAmountPlaceholder: 'Veuillez entrer le montant du retrait',
    // 校验相关
    rules: {
      noWithdrawAmountError: 'Le montant du retrait est requis',
      withdrawAmountError: 'Le montant du retrait doit être supérieur au montant minimum de retrait'
    },
    bankNotOk: 'Les informations bancaires sont incomplètes',
    aboutWithdraw: 'À propos du retrait',
    processTime: 'Temps de traitement',
    minimumAmount: 'Montant minimum de retrait',
    arrivalTime: 'Temps d’arrivée',
    fees: 'Frais',
    withdrawBtn: 'Retirer',
    withdrawNo: 'Non',
    price: 'Prix',
    withdrawStatus: {
      auditing: 'en cours de traitement',
      approved: 'en cours de traitement',
      success: 'réussi',
      'audit failed': 'échoué',
      'withdraw failed': 'échoué',
    }
  },
  payment: {
    productName: 'Nom',
    productPrice: 'Prix',
    chooseMethod: 'Choisissez une méthode de paiement',
    chooseMethodError: 'Veuillez choisir une méthode de paiement',
    pay: 'Payer',
    paySuccess: 'Paiement réussi',
    payFailed: 'Paiement échoué',
  },
  myTeam: {
    level: 'Niveau',
    numbers: 'Nombre',
    totalCommission: 'Commission totale',
    todayCommission: 'Commission du jour',
    commission: {
      invite: 'Inviter',
      commissionList: 'Liste des commissions',
      totalRecharge: 'Recharge totale',
      myCommission: 'Ma commission',
    },
  },
  share: {
    link: 'Lien',
    promotionMaterial: 'Matériel promotionnel',
    promotionContent: 'Une nouvelle plateforme de gains légitimes a été lancée. Ici, vous pouvez soit investir un plan pour gagner un revenu quotidien important, soit inviter vos amis pour gagner des commissions. Les commissions de parrainage de 3 niveaux sont de 30 %, 4 % et 1 %. Inscrivez-vous pour obtenir un bonus de NGN 200 et un produit gratuit d’une valeur de NGN 1000. Inscrivez-vous via le lien ci-dessous'
  },
  paymentRecord: {
    paymentRecord: 'Historique des paiements',
    remark: 'Si votre produit n’apparaît pas après le paiement, veuillez suivre ces instructions pour télécharger votre reçu de transaction qui doit contenir l’heure du paiement, et n’oubliez pas de nous le soumettre pour examen. Votre commande de paiement sera confirmée dans un délai maximum de 24 heures.',
    payStatus: {
      paid: 'Payé',
      unPaid: 'Non payé',
      pending: 'En attente'
    },
    orderNo: 'Numéro de paiement',
    productName: 'Produit',
    price: 'Prix',
    upload: 'Télécharger',
    uploadBtn: 'Télécharger la preuve',
    congrats: 'Félicitations !',
    uploadProof: 'Télécharger la preuve',
    uploadImg: 'Veuillez sélectionner un raccourci',
    uploadProofDesc: 'Description',
    uploadProofDescPlaceHolder: 'Veuillez entrer la description'
  },
  mine: {
    myTeam: 'Mon équipe',
    faq: 'FAQ',
    official: 'Canal officiel',
    paymentRecord: 'Historique des paiements',
    referralLink: 'Lien de parrainage',
    bankAccount: 'Compte bancaire',
    aboutUs: 'À propos de nous',
    logOut: 'Déconnexion',
    logOutMessage: 'Êtes-vous sûr de vouloir vous déconnecter ?'
  }
};