<script setup lang="ts">

import { getOrderPage, PayOrderVO } from '@/api/finance/trading/pay/order';

defineOptions({
  name: 'RecordList'
});

import RecordItem from './RecordItem.vue';

const { t } = useI18n();

const payOrderList = ref<PayOrderVO[]>([]);

const getPayOrderList = async (pageNo: number, pageSize: number) => {
  const res = await getOrderPage({ pageNo, pageSize });
  return res;
};

const listRef = ref();
const reloadList = () => {
  listRef.value.reload();
};

onActivated(() => {
  reloadList();
})

</script>

<template>
<!--  <div class="row text-primary q-py-md">-->
<!--    <q-separator vertical size="4px" color="primary" />-->
<!--    <div v-text="t('paymentRecord.paymentRecord')" class="q-pl-sm text-subtitle2 text-weight-bold" />-->
<!--  </div>-->
  <q-list class="col-grow row payment-list">
    <InfiniteLoad ref="listRef" :data-list="payOrderList" :load-func="getPayOrderList">
      <template #default="{ items }">
        <q-item v-for="(item, index) in items"
                :key="index" class="q-px-none"
        >
          <RecordItem :item="item as PayOrderVO" @uploaded="reloadList" />
        </q-item>
      </template>
    </InfiniteLoad>
  </q-list>
</template>

<style scoped>

</style>